import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Row from '../components/Row'
import Column from '../components/Column'

import HeaderImage from '../images/header_image.jpg'

const IndexPage = () => {
  return (
    <Layout headerImage={HeaderImage} pageTitle="Home">
      <section className="bg-blue" id="about">
        <Container>
          <Row className="vertically-align">
            <Column columnWidth={2} className="border-right">
              <h2>Janik Urbach</h2>
              <p>
                Architekturstudent · Digital Content Creator
              </p>
              <p>„Phantasie ist unser guter Genius oder unser Dämon.“<br />~ Immanuel Kant</p>
            </Column>
            <Column columnWidth={2}>
              <Row className="vertically-align">
                <Column columnWidth={2}>
                  <StaticImage
                    className="rounded"
                    src="../images/janik.jpg"
                    placeholder="blurred"
                    layout="fixed"
                    quality={100}
                    width={200}
                    height={200}
                  />
                </Column>
                <Column columnWidth={2}>
                  <p>18.12.1999</p>
                  <p>Wolfsburg/Wuppertal</p>
                  <p>kontakt<span style={{display:'none'}}>sp0m</span>@janikurbach.de</p>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
